<template>
    <div class="resource-wrap flex-align">
        <div class="banner">
            <el-carousel arrow="always" indicator-position="outside" ref="banner" :loop="true" :autoplay="false">
                <el-carousel-item v-for="(item,index) in courses" :key='index'>
                    <div class="banneritem flex-column" v-for="(each,index) in item" :key='index'
                        @click="toCourse(each.id,index)">
                        <img :src="downloadURL + each.cover"
                            :onerror="$store.state.course.defaultCourseCover[index+1 % 6]">
                        <span>{{each.name}}</span>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            courses: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {

            }
        },
        mounted() {
            // console.log('courses', this.courses)
            console.log(this.downloadURL)
        },
        methods: {
            // 跳转课程页
            toCourse(id, index) {
                console.log('跳转课程页面', id)
                this.$router.push({
                    path: `/course/${id}`,
                    query: {
                        index: index,
                        activityId: this.$route.params.id,
                        breadNumber: 5,
                    }
                });
            },
        }
    }
</script>

<style lang="less" scoped>
    .resource-wrap {
        img {
            width: 36px;
            height: 36px;
        }

        .banner {
            width: 100%;
            // height: 135px;

            .el-carousel__item {
                display: flex;
                align-items: center;
                justify-content: space-around;

                .banneritem {
                    align-items: center;
                    width: 244px;
                    height: 200px;
                    border-radius: 5px;
                    border: 1px solid #E9E9E9;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 14px;

                    img {
                        width: 219px !important;
                        height: 135px !important;
                        margin-bottom: 9px;
                        border-radius: 5px;
                        flex-shrink: 0;
                    }

                    span {
                        color: #333;
                        font-size: 12px;
                        // overflow: hidden;
                        // white-space: nowrap;
                        // text-overflow: ellipsis;
                        // flex-shrink: 0;
                    }
                }

            }

        }

        /deep/ .el-carousel__arrow--right {
            right: 0
        }

        /deep/ .el-carousel__arrow--left {
            left: 0
        }

        /deep/ .el-carousel__arrow:hover {
            background-color: RGBA(150, 187, 251, 1)
        }
    }
</style>