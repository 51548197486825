<!-- 新活动详情 -->
<template>
    <div class="activity-detail manage-wrapper">
        <div class="top flex-layout">
            <div class="activepic">
                <img :src="imgurl+activeDetail.cover" :onerror="$store.state.course.defaultActivityBigCover">
            </div>
            <div class="activeinfo flex-column">
                <p>{{activeDetail.name}}</p>
                <div class="project"><span>所属项目:</span><span>{{activeDetail.projectName}}</span></div>
                <div class="time"><span>活动时间:</span><span>{{activeDetail.startTime}}-{{activeDetail.endTime}}</span>
                </div>
                <div class="introduce"><span>活动简介:</span>
                    <span>{{activeDetail.introduce ||  '暂无简介'}}</span>
                </div>
                <div class="rule csp" @click="toRule">活动规则</div>
            </div>
        </div>
        <Empty :show="stageList.length <=0 " :text="'暂无活动'"></Empty>
        <div class="middle flex-align" v-if="stageList.length > 0">
            <div class="games flex-column">
                <div :class="{'gameitem1':index%3==0,'gameitem2':index%3==1,'gameitem3':index%3==2,'active':index%3==0&&currentind==index,'active2':index%3==1&&currentind==index,'active3':index%3==2&&currentind==index}"
                    v-for="(item,index) in stageList" :key='index' @click="chooseStage(index,item.id)" class="csp">
                    <div class="itemtop flex-between ">
                        <div class="title wes-2">{{item.name}}</div>
                        <div class="status flex-align">
                            <img src="../../assets/images/active/play.gif" v-if="currentind==index">
                            <img src="../../assets/images/active/icon.png" v-else>
                            <div class="text">{{item.status==1?'未开始':item.status==2?'进行中':'已完成'}}</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="bottom flex-column">
                        <span>已完赛人数:{{item.finishCount?item.finishCount:0}}人</span>
                        <span v-if="gameInfo">{{item.startTime.split(' ')[0]}}——{{item.endTime.split(' ')[0]}}</span>
                    </div>
                </div>

            </div>
            <div class="gameinfo">
                <div class="infoitem flex-align-center csp" @click="simulate">
                    <img class="exercise" src="../../assets/images/active/exercise.png" />
                    <div class="right flex-column">
                        <img src="../../assets/images/active/font1.png" />
                        <span
                            v-if="gameInfo.simulateStartTime">{{gameInfo.simulateStartTime.split(" ")[0]}}—{{gameInfo.simulateEndTime.split(" ")[0]}}</span>
                    </div>
                </div>
                <div class="infoitem flex-align-center csp" @click="game">
                    <img class="race" src="../../assets/images/active/race.png" />
                    <div class="right flex-column">
                        <img src="../../assets/images/active/font2.png" />
                        <span
                            v-if="gameInfo.formalStartTime">{{gameInfo.formalStartTime.split(" ")[0]}}-{{gameInfo.formalEndTime.split(" ")[0]}}</span>
                    </div>
                </div>
                <div class="infoitem flex-align-center csp" @click="queryResult">
                    <img class="search" src="../../assets/images/active/search.png" />
                    <div class="right flex-column">
                        <img src="../../assets/images/active/font3.png" />
                        <span
                            v-if="gameInfo.queryStartTime">{{gameInfo.queryStartTime.split(" ")[0]}}—{{gameInfo.queryEndTime.split(" ")[0]}}</span>
                    </div>
                </div>
                <div class="infoitem flex-align-center csp" @click="report">
                    <img class="report" src="../../assets/images/active/report.png" />
                    <div class="right flex-column">
                        <img src="../../assets/images/active/font4.png" />
                        <span
                            v-if="gameInfo.stageStartTime">{{gameInfo.stageStartTime.split(" ")[0]}}—{{gameInfo.stageEndTime.split(" ")[0]}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="read" v-if="courseInfos.length > 0">共读资源圈</div>
        <div class="resource" v-for="(item,index) in courseInfos" :key="index">
            <p class="resource-title">{{item.title}}</p>
            <resource :courses="item.result"></resource>
            <!-- <div class="resource-wrap flex-align">
                <img src="../../assets/images/active/prev.png" class="prev" @click="toggle('prev')">
                <div class="banner">
                    <el-carousel arrow="never" indicator-position="none" ref="banner">
                        <el-carousel-item v-for="(each,index) in item.courses" :key='index'>
                            <div class="banneritem flex-column" @click="toCourse(each.id,index)">
                                <img :src="imgurl+each.courseCover"
                                    :onerror="$store.state.course.defaultCourseCover[index+1 % 6]">
                                <span>{{each.name}}</span>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <img src="../../assets/images/active/next.png" class="next" @click="toggle('next')">
            </div> -->
        </div>
        <!-- 比赛开始弹窗 -->
        <el-dialog :visible="dialogVisible" :show-close="showClose">
            <img class="cup" src="../../assets/images/active/cup.png" />
            <span class="begin">准备好了吗？立即开始比赛？</span>
            <!-- 您只有一次考试机会， -->
            <span class="tip">请您在规定时间内完成比赛，如果您在比赛时关闭页面或退出系统，后台依然会为您计时，在比赛时间结束时系统将自动将已作答结果。</span>
            <div class="btn">
                <div class="error" @click="close">点错了</div>
                <div class="ready" @click="ready">准备好了!</div>
            </div>
        </el-dialog>
        <!-- 其他弹窗 -->
        <el-dialog :visible="showDialog" :show-close="showClose">
            <img v-if="popStatus==2" class="notjoin" src="../../assets/images/active/notjoin.png" />
            <img v-if="popStatus==1" class="medal" src="../../assets/images/active/medal.png" />
            <img v-if="popStatus==0" class="tipimg" src="../../assets/images/active/tip.png" />
            <img class="close" src="../../assets/images/active/close.png" @click="closeDialog" />
            <span class="tips" :class="{'joinSimulate':joinSimulateState==3}">{{tiptext}}</span>
            <div class="btn" v-if="joinSimulateState == 3">
                <div class="error" @click="showDialog=false">点错了</div>
                <div class="ready" @click="goToSimulate">准备好了!</div>
            </div>
        </el-dialog>
    </div>
    <!-- </div> -->
</template>
<script>
    import empty from '../../common/community/empty.vue';
    import resource from './components/resource-carousel.vue';
    export default {
        components: {
            empty,
            resource
        },
        data() {
            return {
                showDialog: false,
                dialogVisible: false,
                showClose: false,
                activeDetail: {},
                stageList: [],
                stageId: null,
                currentStageName: null,
                resourceList: [],
                imgurl: this.downloadURL,
                tiptext: '',
                gameInfo: {},
                popStatus: 0, // 0-喇叭图标,1-奖牌图标,2-未参与图标
                currentind: 0,
                courseInfos: [], // 共读资源圈数据
                joinSimulateState: null,
            }
        },
        methods: {
            // 检查是否能参与模拟练习
            async checkMock() {
                let resData = await this.$Api.Activity.checkMock(this.stageId)
                console.log(resData);
                this.joinSimulateState = resData.data.status;
                if (resData.data.status == 3) {
                    this.popStatus = 0;
                    this.tiptext = '每日可进行2次模拟练习，中途退出不提交也算一次作答，你准备好了吗？'
                    this.showDialog = true;
                    return
                }
                if (resData.data.status == 5) {
                    this.popStatus = 0;
                    this.tiptext = '今日次数您已用完'
                    this.showDialog = true;
                    return
                }
            },
            // 参与模拟练习
            goToSimulate() {
                this.$router.push({
                    path: '/match',
                    query: {
                        type: 0,
                        activeName: this.activeDetail.name,
                        stageId: this.stageId,
                        activeId: this.$route.params.id,
                        breadNumber: 5,
                    }
                })
                return
            },
            // 模拟练习
            simulate() {
                // 1 未开始 2进行中 3已结束
                if (this.gameInfo.simulateStatus == 1) {
                    // 活动未开始
                    this.popStatus = 0;
                    this.tiptext = '活动未开始，敬请期待'
                    this.showDialog = true;
                    return
                }
                if (this.gameInfo.simulateStatus == 2) {
                    // 活动进行中
                    this.checkMock();
                    return;
                }
                if (this.gameInfo.simulateStatus == 3) {
                    // 活动已结束
                    this.popStatus = 0;
                    this.tiptext = "模拟练习时间已结束，该功能已关闭!"
                    this.showDialog = true;
                }
            },
            // 正式比赛
            game() {
                if (this.gameInfo.formalStatus == 1) {
                    this.popStatus = 0;
                    this.tiptext = '活动未开始，敬请期待'
                    this.showDialog = true;
                    return
                }
                if (this.gameInfo.formalStatus == 2) {
                    this.$Api.Activity.isAnswer(this.stageId)
                        .then(res => {
                            console.log('查询成功', res);
                            /**
                             *   状态：
                             * 0  活动还未开始
                             * 1  恭喜您完成比赛，敬请期待公布成绩
                             * 2 很遗憾您没有完成比赛，感谢您的参与
                             * 3 用户可以正常答题
                             * 4  活动已结束
                             */
                            if (res.data.status == 1) {
                                this.popStatus = 1;
                                this.tiptext = '恭喜您完成比赛，敬请期待公布成绩吧！'
                                this.showDialog = true;
                                return
                            }
                            if (res.data.status == 2) {
                                this.tiptext = '很遗憾您没有参与比赛，期待您的下次参与！'
                                this.showDialog = true;
                                return
                            }
                            if (res.data.status == 3) {
                                this.dialogVisible = true;
                            }

                        })
                        .catch(err => {
                            console.log('查询失败', err)
                        })
                    return
                }
                if (this.gameInfo.formalStatus == 3) {
                    this.popStatus = 1;
                    this.tiptext = '恭喜您完成比赛，敬请期待公布成绩吧！'
                    this.showDialog = true;
                }

            },
            // 成绩查询
            queryResult() {
                if (this.gameInfo.queryStatus == 1) {
                    this.popStatus = 0;
                    this.tiptext = '成绩暂未公布，敬请期待'
                    this.showDialog = true;
                    return
                }
                if (this.gameInfo.queryStatus == 2 || this.gameInfo.queryStatus == 3) {
                    this.$router.push({
                        path: '/result',
                        query: {
                            stageId: this.stageId,
                            activeName: this.activeDetail.name,
                            stage: this.currentStageName,
                            breadNumber: 5,
                        }
                    })
                    return
                }
            },
            // 大赛报告
            report() {
                if (this.$store.state.user.role == 'teacher') {
                    this.$router.push({
                        path: `/myActivity/report/${this.stageId}?breadNumber=5`,
                    })
                    return;
                }
                this.$router.push({
                    path: '/report',
                    query: {
                        stageId: this.stageId,
                        breadNumber: 5,
                    }
                })
            },
            close() {
                this.dialogVisible = false
            },
            closeDialog() {
                this.showDialog = false
            },
            ready() {
                // console.log("准备好了");
                this.dialogVisible = false;
                this.$router.push({
                    path: '/match',
                    query: {
                        type: 1,
                        activeName: this.activeDetail.name,
                        stageId: this.stageId,
                        activeId: this.$route.params.id,
                        breadNumber: 5,
                    }
                })
            },
            // 获取活动详情
            detail() {
                let activityId = this.$route.params.id
                this.$Api.Activity.getActiveDetail(activityId)
                    .then(res => {
                        // console.log('获取成功', res)
                        this.activeDetail = res.data
                        this.$route.meta.title = this.activeDetail.name;
                        this.$store.commit('breadPageNameChange', this.activeDetail.name)
                    })
                    .catch(err => {
                        console.log('获取失败', err)
                    })
            },
            // 获取阶段列表
            getList() {
                let data = {
                    activityId: this.$route.params.id,
                    page: 1,
                    limit: 25
                }
                this.$Api.Activity.getStageList(data)
                    .then(res => {
                        // console.log('获取成功', res)
                        this.stageList = res.data.list;
                        this.stageId = res.data.list[0].id;
                        this.currentStageName = res.data.list[0].name;
                        this.getGameInfo(this.stageId)
                    })
                    .catch(err => {
                        console.log('获取失败', err)
                    })
            },
            // 选择阶段
            chooseStage(index, id) {
                // 先判断是否通过初赛
                this.$Api.Activity.isPass(id)
                    .then(res => {
                        console.log('检查成功', res)
                        if (res.data) {
                            this.stageId = this.stageList[index].id;
                            this.currentStageName = this.stageList[index].name;
                            this.getGameInfo(this.stageId);
                            this.currentind = index
                        } else {
                            this.popStatus = 2;
                            this.tiptext = '很遗憾，您没有通过上一环节比赛，无法参与本环节活动'
                            this.showDialog = true;
                        }
                    })
                    .catch(err => {
                        console.log('检查失败', err)
                    })



            },
            // 根据环节id查询对应的比赛信息
            getGameInfo(stageId) {
                let data = stageId
                this.$Api.Activity.getGameInfo(data)
                    .then(res => {
                        // console.log('获取成功', res);
                        this.gameInfo = res.data
                    })
                    .catch(err => {
                        console.log('获取失败', err)
                    })
            },
            // 获取共度资源圈
            // getResouce() {
            //     let data = {
            //         activityId: this.$route.params.id,
            //         page: 1,
            //         limit: 5
            //     }
            //     this.$Api.Activity.getResouce(data)
            //         .then(res => {
            //             console.log('获取共读资源圈成功', res);
            //             var result = [];
            //             for (var i = 0, len = res.data.length; i < len; i += 4) {
            //                 result.push(res.data.slice(i, i + 4));
            //             }
            //             // console.log(result)
            //             this.resourceList = result
            //         })
            //         .catch(err => {
            //             console.log('获取失败', err)
            //         })
            // },
            // 获取共读资源圈数据
            async getResouce() {
                let resData = await this.$Api.Activity.getActivityDetail(this.$route.params.id)
                console.log('共读资源圈', resData.data.courseInfos)
                let info = resData.data.courseInfos;
                if(!info || JSON.stringify(info) == '[]') return;
                info.map(item => {
                    // item.courses.push(item.courses[0])
                    // item.courses.push(item.courses[0])
                    // item.courses.push(item.courses[0])
                    // item.courses.push(item.courses[0])
                    // item.courses.push(item.courses[0])
                    var result = [];
                    for (var i = 0, len = item.courses.length; i < len; i += 3) {
                        result.push(item.courses.slice(i, i + 3));
                    }
                    console.log(result);
                    item.result = result;
                })
                this.courseInfos = info;
            },
            // 轮播图切换
            toggle(type) {
                if (type == 'next') {
                    this.$refs.banner.next()
                } else {
                    this.$refs.banner.prev()
                }
            },
            // 跳转课程页
            toCourse(id, index) {
                console.log('跳转课程页面', id)
                this.$router.push({
                    path: `/course/${id}`,
                    query: {
                        // proid: this.proid,
                        index: index,
                        // from: 'myActivity',
                        activityId: this.$route.params.id,
                        breadNumber: 5,
                    }
                });
            },
            // 活动规则
            toRule() {
                console.log('跳转到活动规则')
                this.$router.push({
                    path: `/activity/rule/${this.$route.params.id}`,
                    query: {
                        from: 'myActivity',
                        breadNumber: 5,
                    },
                })
            }
        },
        mounted() {
            this.detail();
            this.getList();
            this.getResouce()
        }
    };
</script>
<style lang='less' scoped>
    .activity-detail {
        cursor: default;

        // /deep/ .el-breadcrumb {
        //     color: #333;
        //     margin: 49px 0 32px;
        // }

        .top {
            box-shadow: 0px 2px 10px 0px #F5F8FA;
            // min-width: 1200px;
            box-sizing: border-box;
            height: 356px;
            border-radius: 5px;
            padding: 48px 33px;
            background: #fff;
            // margin-top: 50px;

            .activepic {
                width: 437px;
                height: 261px;
                border-radius: 5px;
                margin-right: 44px;
                flex-shrink: 0;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .activeinfo {
                justify-content: space-around;
                width: 100%;

                p:first-child {
                    color: #333;
                    font-size: 16px;
                    font-weight: bold;
                }

                div {
                    font-size: 14px;
                    color: #999;

                    span:first-child {
                        font-weight: bold;
                    }
                }

                .introduce {
                    word-break: break-all;

                    span:last-child {
                        line-height: 24px;
                    }
                }

                .rule {
                    width: 112px;
                    height: 34px;
                    border-radius: 5px;
                    background: #508EF9;
                    color: #fff;
                    text-align: center;
                    line-height: 34px;
                    font-size: 15px;
                    margin-left: auto;
                }
            }

        }

        .middle {
            box-shadow: 0px 2px 10px 0px #F5F8FA;
            // min-width: 1200px;
            height: 535px;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 51px 25px;
            background: #fff;
            margin-top: 23px;

            @media screen and (max-width:1250px) {
                height: 520px;
                padding: 40px 15px;
            }


            .games {
                height: 100%;
                margin-right: 23px;
                overflow-y: auto;
                flex-shrink: 0;
                padding-right: 10px;
                // -ms-overflow-style: none;
                // /* IE 10+ */
                // scrollbar-width: none;

                // /* Firefox */
                // &::-webkit-scrollbar {
                //     display: none;
                //     /* Chrome Safari */
                // }
                /*修改滚动条样式*/
                &::-webkit-scrollbar {
                    width: 8px;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 2px;
                }

                &::-webkit-scrollbar-button {
                    height: 0px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #bfbfbf;
                    border-radius: 4px;
                }

                .gameitem {
                    width: 315px;
                    height: 129px;
                    border-radius: 5px;
                    padding: 11px 17px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 23px;
                    flex-shrink: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @media screen and (max-width:1250px) {
                        width: 300px;
                    }

                    .itemtop {
                        .title {
                            padding: 0 5px;
                            // height: 23px;
                            margin-right: 5px;
                            border-radius: 50px;
                            color: #fff;
                            font-size: 14px;
                            font-weight: bold;
                            // background-color: rgba(255, 255, 255, 0.2);
                            text-align: center;
                            line-height: 23px;
                        }

                        .status {
                            img {
                                width: 14px;
                                height: 15px;
                            }

                            .text {
                                width: 46px;
                                height: 17px;
                                border-radius: 50px;
                                font-size: 9px;
                                color: #fff;
                                margin-left: 10px;
                                background-color: rgba(255, 255, 255, .2);
                                text-align: center;
                                line-height: 17px;
                            }
                        }
                    }

                    .line {
                        width: 100%;
                        height: 1px;
                        background-color: #fff;
                        margin-top: 10px;
                    }

                    .bottom {
                        color: #fff;
                        flex-grow: 1;
                        justify-content: space-around
                    }
                }

                .gameitem1 {
                    .gameitem();
                    background-image: url('../../assets/images/active/game01.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .gameitem2 {
                    .gameitem();
                    background-image: url('../../assets/images/active/game02.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .gameitem3 {
                    .gameitem();
                    background-image: url('../../assets/images/active/game03.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .active {
                    .gameitem();
                    background-image: url('../../assets/images/active/active1.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .active2 {
                    .gameitem();
                    background-image: url('../../assets/images/active/active2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .active3 {
                    .gameitem();
                    background-image: url('../../assets/images/active/active3.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

            }

            .gameinfo {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
                height: 100%;

                .infoitem {
                    width: 386px;
                    height: 192px;
                    border-radius: 10px;
                    background-color: #fff;
                    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.11);
                    box-sizing: border-box;

                    .exercise {
                        width: 91px;
                        height: 90px;
                        margin-right: 25px;
                    }

                    .race {
                        width: 90px;
                        height: 90px;
                        margin-right: 25px;
                    }

                    .search {
                        width: 91px;
                        height: 89px;
                        margin-right: 25px;
                    }

                    .report {
                        width: 91px;
                        height: 75px;
                        margin-right: 25px;
                    }

                    .right {
                        align-items: center;

                        img {
                            width: 104px;
                            height: 24px;
                            margin-bottom: 15px;
                        }

                        span {
                            font-size: 13px;
                            color: #8F95B3;
                        }
                    }

                    @media screen and (max-width:1250px) {
                        width: 300px;
                    }
                }
            }

        }

        .read {
            font-size: 20px;
            color: #333;
            font-weight: bold;
            margin: 27px 32px;
        }

        .resource {
            margin-bottom: 25px;
            box-shadow: 0px 2px 10px 0px #F5F8FA;
            // min-width: 1200px;
            box-sizing: border-box;
            min-height: 285px;
            border-radius: 5px;
            padding: 0 32px;
            background: #fff;
            overflow: hidden;
            // display: flex;
            // align-items: center;

            .resource-title {
                font-size: 18px;
                font-weight: bold;
                margin: 15px auto 0px;
                text-align: center;
            }

            img {
                width: 36px;
                height: 36px;
            }

            .banner {
                width: 100%;
                // height: 135px;

                .el-carousel__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .banneritem {
                        align-items: center;
                        width: 244px;
                        height: 200px;
                        border-radius: 5px;
                        border: 1px solid #E9E9E9;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 14px;

                        img {
                            width: 219px !important;
                            height: 135px !important;
                            margin-bottom: 9px;
                            border-radius: 5px;
                            flex-shrink: 0;
                        }

                        span {
                            color: #333;
                            font-size: 12px;
                            // overflow: hidden;
                            // white-space: nowrap;
                            // text-overflow: ellipsis;
                            // flex-shrink: 0;
                        }
                    }

                }


            }

        }

        /deep/ .el-dialog {
            width: 532px;
            height: 312px;
            background-image: url("../../assets/images/active/popbg2.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            border-radius: 10px;

            .cup {
                position: absolute;
                top: -21px;
                width: 191px;
                height: 131px;
            }

            .medal {
                width: 157px;
                height: 217px;
                position: absolute;
                top: -40px;
            }

            .tipimg {
                width: 145px;
                height: 143px;
                position: absolute;
                top: -44px;
                left: 50%;
                transform: translateX(-50%);
            }

            .notjoin {
                width: 180px;
                height: 98px;
                position: absolute;
                top: -12px;
                left: 43%;
                transform: translateX(-50%);
            }

            .close {
                width: 28px;
                height: 28px;
                position: absolute;
                top: 14px;
                right: 15px;
            }

            .tips {
                text-align: center;
                margin-top: 120px;
                font-size: 18px;
                color: #333;

                &.joinSimulate {
                    margin-top: 100px;
                    margin-bottom: 20px;
                }
            }


            .begin {
                color: #508EF9;
                font-size: 24px;
                margin-top: 50px;
            }

            .tip {
                color: #333;
                font-size: 15px;
                line-height: 24px;
                margin-top: 24px;
                margin-bottom: 20px;
            }

            .btn {
                .error {
                    width: 149px;
                    height: 38px;
                    border-radius: 5px;
                    line-height: 38px;
                    text-align: center;
                    color: #666;
                    font-size: 16px;
                    border: 1px solid #508EF9;
                    box-sizing: border-box
                }

                .ready {
                    .error();
                    color: #fff;
                    margin-left: 64px;
                    background: #508EF9;
                    border: none
                }
            }
        }

        /deep/ .el-dialog__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: none !important;

            .btn {
                display: flex;
            }
        }
    }
</style>